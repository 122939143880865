


















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Chart, ViewItem } from '@/models'
import { ChartFragment } from './fragments'
import Loading from '@/components/Loading.vue'
import ComponentHeader from '@/components/ViewComponentHeader.vue'
import FilterForm from '@/components/form/FilterForm.vue'
import ChartResult from './ChartResult.vue'
import gql from 'graphql-tag'
import xlsx from 'xlsx'
import { GraphQLError } from 'graphql'

interface FilterValue {
  filterId?: string
  filterOptions: Record<string, any>
}

@Component({
  components: {
    ChartResult,
    Loading,
    ComponentHeader,
    FilterForm
  },
  apollo: {
    chart: {
      query: gql`
        query Chart($chartId: ID) {
          chart(chartId: $chartId) {
            ...Chart
            chartTypeId
            filters {
              _id
              title
              schema: serializedSchema(includeParameters: true)
              formSchema: serializedSchema(includeParameters: false)
            }
          }
        }
        ${ChartFragment}
      `,
      variables() {
        return {
          chartId: this.componentId
        }
      }
    }
  }
})
export default class ChartView extends Vue {
  @Prop({ type: String }) environmentId!: string
  @Prop({ type: String }) componentId!: string
  @Prop({ type: Boolean, default: false }) preview!: boolean
  @Prop({ type: Boolean, default: false }) editing!: boolean
  @Prop({ type: Object, default: () => ({}) }) viewParams!: Record<string, any>
  @Prop({ type: Object, default: () => ({}) }) itemDefinition!: ViewItem

  chart: Chart | null = null
  filter: FilterValue | null = null
  exportable = false

  @Watch('chart')
  setDefaultFilter(chart: Chart) {
    if (chart.filterByDefault) {
      this.filter = {
        filterId: chart.filterByDefault,
        filterOptions: this.viewParams
      }
    } else if (chart.allowsNoFilter) {
      this.filter = { filterId: undefined, filterOptions: this.viewParams }
    } else if (chart.filters && chart.filters.length >= 1) {
      this.filter = {
        filterId: chart.filters[0]._id,
        filterOptions: this.viewParams
      }
    }
  }

  exporting = false
  async exportTableChart() {
    if (!this.chart || this.exporting) return
    try {
      this.exporting = true
      const { data } = await this.$apollo.mutate({
        mutation: gql`
          mutation exportTableChart(
            $chartId: ID
            $filterId: ID
            $filterOptions: JSON
            $params: JSON
          ) {
            result: exportTableChart(
              chartId: $chartId
              filterId: $filterId
              filterOptions: $filterOptions
              params: $params
            )
          }
        `,
        variables: {
          chartId: this.chart._id,
          filterId: this.filter && this.filter.filterId,
          filterOptions: this.filter && this.filter.filterOptions,
          params: this.viewParams
        }
      })
      if (data.result) {
        xlsx.writeFile(
          data.result,
          `${this.chart.title || this.chart.name || 'Documento'}.xlsx`
        )
      } else {
        throw new Error('Error al descargar información.')
      }
    } catch (e) {
      let message = e.message
      if (e.graphQLErrors)
        message = e.graphQLErrors.map((e: GraphQLError) => e.message).join(', ')
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error: ' + message,
        color: 'error',
        timeout: 10000
      })
      console.error(e)
    } finally {
      this.exporting = false
    }
  }
}
